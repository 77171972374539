import axios from 'axios';
import configData from '@/config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.dataLoaders.baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

export const retrieveResourceCalendars = ({
  extractEnvironment,
  customer,
  resourceIds,
  userId,
  dateFrom,
  dateTo,
}) => {
  const params = new URLSearchParams();
  params.append('resourceIds', resourceIds);
  params.append('dateFrom', dateFrom);
  params.append('dateTo', dateTo);

  const options = {
    method: 'GET',
    params,
    url: '/extractor/resourceCalendars',
  };
  apiClient.defaults.headers.userid = userId;
  apiClient.defaults.headers.environment = extractEnvironment;
  apiClient.defaults.headers.customer = customer;
  return apiClient(options);
};

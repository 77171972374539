<template>
  <div class="box">
    <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
    <p
      class="is-size-4 has-text-weight-semibold has-text-centered has-text-primary"
    >
      {{ $t('Resource Working Calendar Extraction') }}
    </p>
    <b-field grouped style="width: 40vw">
      <b-field expanded label="Date From">
        <b-datepicker
          v-model="dateFrom"
          placeholder="Click to select..."
          icon="calendar"
          :date-formatter="$d"
          :first-day-of-week="1"
        ></b-datepicker>
      </b-field>
      <b-field expanded label="Date To">
        <b-datepicker
          v-model="dateTo"
          placeholder="Click to select..."
          icon="calendar"
          :date-formatter="$d"
          :first-day-of-week="1"
        ></b-datepicker>
      </b-field>
      <b-field label="Parent Resource Id">
        <b-input v-model="resourceIds" />
      </b-field>
    </b-field>
    <b-field>
      <b-dropdown v-model="extractEnvironment" hoverable>
        <b-button slot="trigger" icon-left="server" type="is-primary">
          Extract from...
        </b-button>
        <b-dropdown-item
          v-for="option in environmentList"
          :key="option.name"
          :value="option.name"
        >
          {{ option.friendlyName }}
        </b-dropdown-item>
      </b-dropdown>
      <b-input :value="friendlySourceEnvName" disabled></b-input>
      <b-button :disabled="disableFetch" class="is-success" @click="fetchData"
        >Fetch Data</b-button
      >

      <b-button
        :disabled="calendars.length === 0"
        class="is-primary"
        style="margin-left: 10px"
        @click="downloadCsv"
        >Export to CSV</b-button
      >
    </b-field>

    <span v-if="error" style="color: red" class="has-text-weight-semibold">{{
      error
    }}</span>
    <b-table
      :paginated="true"
      :per-page="20"
      :pagination-simple="false"
      scrollable
      :sticky-header="true"
      :data="calendars"
      :columns="columns"
      height="55vh"
      :sort-multiple="true"
    >
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="sad-tear" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import getFriendlyEnvName from '@/lib/getFriendlyEnvName';
import { retrieveResourceCalendars } from '@/services/calendarExtractService.js';

export default defineComponent({
  data() {
    return {
      extractEnvironment: undefined,
      calendars: [],
      dateFrom: undefined,
      dateTo: undefined,
      resourceIds: undefined,
      isLoading: false,
      error: undefined,
    };
  },
  computed: {
    friendlySourceEnvName() {
      return getFriendlyEnvName(this.extractEnvironment, this.environmentList);
    },
    disableFetch() {
      return !(
        this.dateFrom &&
        this.dateTo &&
        this.extractEnvironment &&
        this.resourceIds
      );
    },
    columns() {
      const cols = [];
      this.calendars.forEach((row) => {
        const keys = Object.keys(row);
        keys.forEach((key) => {
          if (!cols.includes(key)) {
            cols.push(key);
            keys.push(key);
          }
        });
      });
      return cols.map((col) => {
        let colDef = {
          field: col,
          label: col,
          sortable: true,
        };
        if (colDef.field === 'date') {
          colDef.sticky = true;
          colDef.headerClass = 'is-sticky-column-one';
          colDef.cellClass = 'is-sticky-column-one';
        }
        if (colDef.field === 'resourceId') {
          colDef.sticky = true;
          colDef.headerClass = 'is-sticky-column-two';
          colDef.cellClass = 'is-sticky-column-two';
        }
        return colDef;
      });
    },
  },
  created() {
    this.fetchEnvironments(false);
  },
  methods: {
    async fetchData() {
      this.error = null;
      const dateFromStr = this.$moment(this.dateFrom).format('YYYY-MM-DD');
      const dateToStr = this.$moment(this.dateTo).format('YYYY-MM-DD');
      try {
        this.isLoading = true;
        const { data } = await retrieveResourceCalendars({
          resourceIds: this.resourceIds,
          dateFrom: dateFromStr,
          dateTo: dateToStr,
          userId: this.userId,
          extractEnvironment: this.extractEnvironment,
          customer: this.customer,
        });
        this.calendars = data;
      } catch (err) {
        console.error(err);
        this.error = err.response.data;
      } finally {
        this.isLoading = false;
      }
    },
    downloadCsv() {
      const keys = this.columns.map((col) => {
        return col.field;
      });
      if (this.calendars && this.calendars.length > 0) {
        this.exportCsv('calendars', this.calendars, keys);
      }
    },
  },
});
</script>

<style>
.is-sticky-column-one {
  background: #23d160 !important;
  color: white !important;
}
.is-sticky-column-two {
  background: #167df0 !important;
  color: white !important;
}
</style>
